@font-face {
    font-family: 'anonymous';
    src: url('./assets/fonts/AnonymousPro-Regular.ttf') format('woff2'),
         url('./assets/fonts/AnonymousPro-Regular.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'anonymous';
    src: url('./assets/fonts/AnonymousPro-Bold.ttf') format('woff2'),
         url('./assets/fonts/AnonymousPro-Bold.ttf') format('woff');
    font-weight: bold;
    font-style: normal;
  }